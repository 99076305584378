<template>
  <div>
    <search-FuneralHome @submit="submitSearch" />

    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>

    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <template slot="name" slot-scope="name,record">
        <router-link :to="{name:'halls',params:{id: record.id}}">{{ name }}</router-link>
      </template>
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          :disabled="false"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>
      <span slot="remark" slot-scope="text" class="custom-ellipsis custom-word-break-all">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          {{ text }}
        </a-tooltip>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record)">编辑</a>
          <a @click="showOperationHistoryModal(record.id)">操作历史</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增殡仪馆模态框 -->
    <new-funeral-home
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑殡仪馆模态框 -->
    <edit-funeral-home
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="FuneralHome"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findFuneralHomes, updateFuneralHomeEffective } from '@/api/funeral_home'
import { hasPermission } from '@/utils/permission'
import SearchFuneralHome from '@/views/funeral_homes/tenant/Search'

export default {
  name: 'FuneralHomeList',
  components: {
    Pagination,
    SearchFuneralHome,
    NewFuneralHome: () => import('@/views/funeral_homes/tenant/New'),
    EditFuneralHome: () => import('@/views/funeral_homes/tenant/Edit'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingRecord: {}, // 正在编辑的记录id
      isShowOperationHistoryModal: false,
      showingOperationHistoryId: 0,
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '殡仪馆名称',
          dataIndex: 'name',
          width: 170,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '地图地址',
          dataIndex: 'address',
          width: 170
        },
        {
          title: '自定义地址',
          dataIndex: 'custom_address',
          width: 170
        },
        {
          title: '经度',
          width: 120,
          dataIndex: 'longitude'
        },
        {
          title: '纬度',
          width: 120,
          dataIndex: 'latitude'
        },
        {
          title: '介绍',
          width: 190,
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' }
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '操作',
          width: 220,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    isHasEditPermission() {
      return true
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findFuneralHomes(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked

      this.$confirm({
        title: effective ? '确定进行生效吗?' : '确定进行失效吗？\n' +
            '\n',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateFuneralHomeEffective(record.id, { effective: effective })
            .then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            })
            .catch(() => {
              vm.editingEffectiveId = 0
            })
        }
      })
    }
  }
}
</script>
